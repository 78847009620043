body{
  margin:0;
  padding:0;
  font-family: 'Roboto', sans-serif;
}
.brand{
  font-family: 'Righteous', cursive;
}

section{
  margin:0 120px;
}

header{
  display:grid;
  grid-template-columns: 1fr 1fr;
  margin-top:50px;
}

header div{
  margin-left:auto;
  font-size:20px;
}

header ul li {
  display:inline-block;
  margin:0 0 20px 20px;
}
header ul li a{
  color:rgb(39, 39, 39);
  text-decoration:none;
}
header ul li a:hover{
  color:rgb(39, 39, 39);
  font-weight: 600;
  border-bottom: 5px solid rgba(71, 0, 184, 0.9);
}



#main{
  margin-top:100px;
  display:grid;
  grid-template-columns:repeat(12, 1fr);
  grid-template-rows: auto;
}

.main-text{
  font-size: 5vw;
  font-weight:700;
  line-height: 2.1;
  grid-column: span 6;
}

.right-image{
  grid-column:span 6;
}
.right-image img{
  width: 70%;
  padding:20px;
  display:block;
  margin:0 auto;
}
@media screen and (max-width:800px){
  .main-text{
    text-align: center;
    font-size: 5vw;
    font-weight:700;
    line-height: 2.1;
    grid-column: span 12;
  }
  
  .right-image{
    grid-column:span 12;
  }

  section{
    margin:0 50px;
  }
}

#main span:nth-of-type(1){
  border-bottom: 10px solid #4700b8;
}

#main span:nth-of-type(2){
  color:white;
  padding: 0px 15px;
  background: rgb(78,2,209);
  background: linear-gradient(90deg, rgba(71, 0, 184, 0.9) 0%, rgb(9, 16, 114) 100%);
  border-radius: 15px;
}


input.input1 {
  margin-top:10px;
  height: 50px;
  border-radius: 25px;
}
.input1 {
    display: block;
    width: 100%;
    margin:0 auto;
    background: #e6e6e6;
    font-family: 'Righteous', cursive;
    font-size: 15px;
    line-height: 1.5;
    color: #666;
}

input, textarea {
  outline: none;
  border: none;
  padding:0;
}


input, textarea {
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  text-align: start;
  -webkit-appearance: textfield;
  background-color: white;
  -webkit-rtl-ordering: logical;
  cursor: text;
  margin: 0em;
  font: 400 13.3333px Arial;}


  button {
    outline:none;
    border:none;
    font-family: 'Righteous', cursive;
    font-size: 20px;
    line-height: 1.5;
    min-width: 193px;
    height: 50px;
    letter-spacing: 2px;
    border-radius: 25px;
    background: rgb(78,2,209);
     background: linear-gradient(90deg, rgba(71, 0, 184, 0.9) 0%, rgb(9, 16, 114) 100%);
    color: #fff;
    justify-content: center;
    align-items: center;
    padding: 0 25px;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    -moz-transition: all .4s;
    transition: all .4s;
}

button:hover {
  font-size: 20px;
  line-height: 1.5;
  min-width: 193px;
  height: 50px;
  letter-spacing: 2px;
  border-radius: 25px;
  background: rgb(78,2,209);
   background: linear-gradient(90deg, rgba(217, 227, 230, 0.9) 0%, rgb(245, 235, 246) 100%);
  color: rgb(78,2,209);
  
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  -moz-transition: all .4s;
  transition: all .4s;
}

#forma{
  margin-top:100px;
  background: rgb(78,2,209);
  background: linear-gradient(90deg, rgba(71, 0, 184, 0.9) 0%, rgb(9, 16, 114) 100%);
  padding:50px;
  color:white;
}

h2{
  text-align: center;
}